import axios from "axios";
import { environment } from "../environments/environment";

export default class SubscriptionService {
  getAll = async () => {
    return axios.get(environment.API_URL + "subscriptions/me");
  };
  add = async (data) => {
    return axios.post(environment.API_URL + "subscriptions", data);
  };
  update = async (id, data) => {
    return axios.put(environment.API_URL + "subscriptions/" + id, data);
  };
  get(id) {
    return axios.get(environment.API_URL + "subscriptions/" + id);
  }
}
