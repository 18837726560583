import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../views/pages/login";

import LayoutSidebar from "../views/layout/layout";
import Subscriptions from "../views/pages/subscriptions";
import Settings from "../views/pages/settings";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import ForgetPassword from "../views/pages/forgetPassword";
import VerifyCode from "../views/pages/verifyCode";
import ChangePassword from "../views/pages/changePassword";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const role = useSelector((state) => state.user.user.role);
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    if (!role || !allowedRoles.includes(role)) {
      navigate("/");
    }
    setAllowed(true);
  }, []);
  return allowed ? children : "";
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/codeVerify",
    element: <VerifyCode />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    path: "/",
    element: <LayoutSidebar />,
    children: [
      {
        path: "subscriptions",
        element: (
          <Subscriptions />
        ),
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function NotFoundPage() {
  return <h1>404 - Page Not Found</h1>;
}

export default router;
