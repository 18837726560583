import { Fragment, useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import "../layout/sideBar.css";
export default function NavBar({ open, setOpen }) {
  return (
    <Navbar className="bg-white navBar_height ">
      <Container fluid className={open ? "partial-navbar" : "full-navbar"}>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
