import React, { useState } from "react";
import Input from "../lib/input/input";
import Button from "../lib/button/button";
import { ArrowRight, Eye, EyeSlash } from "react-bootstrap-icons";
import "../assets/css/login.css";
import Card from "react-bootstrap/Card";
import { login } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import InputGroup from "../lib/input-group/input-group";
import logoDryfit from "../assets/images/logoDryFit.png";
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    account: "",
  });

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const result = emailRegex.test(email);
    if (!result) {
      setErrMsg((prevErrMsg) => ({
        ...prevErrMsg,
        email: result ? "" : "Veuillez saisir une adresse e-mail valide.",
      }));
    }
    return result;
  };

  const validatePassword = (password) => {
    const result = password.length >= 8;
    if (!result) {
      setErrMsg((prevErrMsg) => ({
        ...prevErrMsg,
        password: result
          ? ""
          : "Le mot de passe doit contenir au moins 8 caractères.",
      }));
    }
    return result;
  };

  async function submit() {
    try {
      setErrMsg({
        email: "",
        password: "",
      });

      if ( validatePassword(password)) {
        await login({ email: email, password: password })
          .then(() => {
            return navigate("/");
          })
          .catch((error) => {
            setErrMsg((prevErrMsg) => ({
              ...prevErrMsg,
              account: error.response.data.message,
            }));
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const toggleShowPassword = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };
  const toggleToForgetPassword = () => {
    return navigate(`/forgetPassword`);
  };
  return (
    <div className="login-background">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card>
          <Card.Body>
            <div className="text-center">
              <img className="pt-4 " src={logoDryfit} />
            </div>
            <h5 className="py-5 text-center">
              Merci d'entrer vos informations de connexion
            </h5>
            <div className="d-flex flex-column align-items-center">
              <p className="text-danger">{errMsg.account}</p>
              <Input
                classNames="form-control-input "
                title="Email ou Username"
                type="email"
                placeholder="utilisateur@gmail.com"
                onChangeFunction={handleEmailChange}
                errorMessage={errMsg.email}
              />
              <InputGroup
                classNames="form-control-input-group"
                title="Mot de passe"
                type={passwordType}
                value={password}
                onClickFunction={toggleShowPassword}
                placeholder="********"
                iconEnd={passwordType === "password" ? <EyeSlash /> : <Eye />}
                onChangeFunction={handlePasswordChange}
                errorMessage={errMsg.password}
              />
              <Button
                title={"Mot de passe oublié ?"}
                classNames="forgetPasswordBtn my-3"
                onClickFunction={toggleToForgetPassword}
              />
              <Button
                title={"Se connecter"}
                color="success"
                classNames="formButton"
                iconEnd={<ArrowRight />}
                onClickFunction={submit}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
