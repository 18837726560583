import { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import SubscriptionService from "../../../services/subscription.service";
import SubscriptionPricesService from "../../../services/subscriptionPrices.service";

const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  period: "required",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const subscriptionService = new SubscriptionService();

export default function EditSubscription({
  closeModalFunction,
  subscriptionId,
  onSuccessFunction = () => { },
}) {
  const packsService = new SubscriptionPricesService();
  const [packs, setPacks] = useState([]);
  const [packsPeriods, setPacksPeriods] = useState([]);

  const [subscriptionData, setSubscriptionData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    period: "",
    amount: "",
  });
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});

  function handleInputChange(value, fields) {
    setSubscriptionData((prevState) => {
      const updatedData = {
        ...prevState,
        [fields]: value,
      };

      if (fields === "period") {
        packs.map((pack) => {
          if (pack.period === value) {
            updatedData.amount = pack.currentPrice
          }
        })
        // const index = subscriptionOptions.indexOf(value);
        // updatedData.price = subscriptionPrices[index];
      }

      return updatedData;
    });
  }

  useEffect(() => {
    getAllPacks()
    getSubscription()
  }, []);

  const getAllPacks = async () => {
    try {
      await packsService
        .getAllSubscriptionPacks()
        .then((response) => {
          setPacks(response.data)
          const data = response.data.map((pack) => {
            return pack.period
          })
          setPacksPeriods(data)
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const getSubscription = async () => {
    try {
      await subscriptionService
        .get(subscriptionId)
        .then((response) => {
          setSubscriptionData(response.data)
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const CloseModalAddSubscription = () => {
    closeModalFunction();
  };

  const validation = new Validator(subscriptionData, validationRules);
  validation.setAttributeNames({
    firstname: "nom",
    lastname: "prenom",
    email: "email",
    phone: "téléphone",
    period: "period",
  });

  async function addNewSubscription() {
    if (validation.passes()) {
      const newSubscription = {
        firstname: subscriptionData.firstname,
        email: subscriptionData.email,
        phone: subscriptionData.phone,
        lastname: subscriptionData.lastname,
        period: subscriptionData.period,
      };
      try {
        await subscriptionService.update(subscriptionId, newSubscription);
        showAlert({
          title: "Abonnement ajoutée avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddSubscription();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }

  return (
    <Modal
      onCancelFunction={CloseModalAddSubscription}
      onCloseFunction={CloseModalAddSubscription}
      submitFunction={addNewSubscription}
      cancelText="annuler"
      dialogTitle={
        <BreadcrumbsLink
          firstLinkTitle="Abonnements"
          href="/subscriptions"
          activatedLinkTitle="Ajouter un abonnement"
          color="titleModal"
        />
      }
      applyTitle="Sauvgarder"
      isOpened={true}
      dialogContentChild={
        <div className="row">
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Nom"
              type="text"
              name="firstname"
              value={subscriptionData.firstname}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.firstname && (
              <AlertMessages
                message={validationErrors.firstname}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Prénom"
              type="text"
              name="lastname"
              value={subscriptionData.lastname}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.lastname && (
              <AlertMessages
                message={validationErrors.lastname}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Email"
              type="email"
              name="email"
              value={subscriptionData.email}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.email && (
              <AlertMessages
                message={validationErrors.email}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Téléphone"
              type="text"
              name="phone"
              value={subscriptionData.phone}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.phone && (
              <AlertMessages
                message={validationErrors.phone}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6 mt-4">
            <SelectForm
              onChangeFunction={handleInputChange}
              options={packsPeriods}
              name="period"
              value={subscriptionData.period}
              labelFilter="Periode d’abonnement (mois)"
              selectStyle="priceSubscription"
              classNames="addUser_select"
              // titleSelect="Periode d’abonnement"
              MenuProps={MenuProps}
            />
            {validationErrors && validationErrors.period && (
              <AlertMessages
                message={validationErrors.period}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6 ">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Prix"
              placeholder="sélectionner une periode d'abonnement"
              type="text"
              name="price"
              value={subscriptionData.amount}
              onChangeFunction={handleInputChange}
              readOnly
              disabled
            />
          </div>
        </div>
      }
    />
  );
}
